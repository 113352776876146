@use '../../styles/mixins' as mixins;

.download-module {
    
    position: relative;
    --padding: var(--wp--preset--spacing--60);
    background-color: var(--wp--preset--color--bnpp-green-tint-10);
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: var(--wp--preset--spacing--60);
    padding: var(--padding);
    color: var(--wp--preset--color--bnpp-green);
    text-decoration: none;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        opacity: 0;
        border: solid 4px var(--wp--preset--color--bnpp-green);
        transition: 300ms;
    }

    &:hover::after {
        opacity: 1;
    }

    &+.download-module {
        margin-top: var(--wp--preset--spacing--60);
    }

    &:not(:has(.icon-wrapper)) {
        grid-template-columns: 1fr;
    }

    .icon-wrapper {
        background-color: var(--wp--preset--color--bnpp-green-tint-20);
        display: flex;
        height: fit-content;
        margin-top: calc(var(--padding) / -1);
        margin-left: calc(var(--padding) / -1);
    }

    .content {

        h3 {
            font-size: var(--wp--preset--font-size--heading-s);
            color: var(--wp--preset--color--bnpp-black);
        }
        
        p {
            font-weight: 400;
            margin: 0;
        }

        img {
            width: 25px;
            display: block;
            margin-left: auto;
        }
    }

}